/* eslint-disable no-unused-expressions */

import(/* webpackChunkName: "components" */ './components');

// Load react only if we have components on the page.
const components = document.querySelectorAll('[data-component]');

if (components.length) {
  import(/* webpackChunkName: "app" */ './react');
}
